import { isItemNew } from '../utils';
import { itemTypeNames, buildApiPath } from '../items/item-types';
import { authorizedFetch } from '../utils.js';
import { addError, addSuccess, removeToast } from '../toasts/toasts.actions';
import { cloneDeep } from 'lodash';
import * as itemTypes from '../items/item-types';
import { getEnvironmentConfig } from '../environment-config/environment-config.js';

export const getItem = (
  model,
  receiveAction,
  setIsBusyAction,
  onReceiveItem
) => params => (dispatch, getState) => {
  const { id, type } = params;

  //item is already in reducer
  if (getState()[type].byId[id]) {
    return;
  }
  if (isItemNew(id)) {
    dispatch(receiveAction(model(id, `New ${itemTypeNames[type]}`, params)));
  } else {
    dispatch(setIsBusyAction({ id, isBusy: true }));

    authorizedFetch(buildApiPath(params))
      .then(response => {
        response.type = type;
        if (typeof onReceiveItem === 'function') {
          onReceiveItem(response);
        }
        dispatch(receiveAction(response));
        dispatch(setIsBusyAction({ id, isBusy: false }));

        if (itemTypes.ITEM_TYPES_WITH_HISTORY.includes(type)) {
          const { environment } = getEnvironmentConfig();
          const itemHistoryJson = localStorage.getItem(
            `_minion_${environment}_${type}_history_`
          );

          if (!itemHistoryJson) {
            localStorage.setItem(
              `_minion_${environment}_${type}_history_`,
              JSON.stringify([{ id, name: response.name }])
            );
          } else {
            const itemHistory = JSON.parse(itemHistoryJson);

            const newItemHistory = itemHistory.filter(item => item.id !== id);
            newItemHistory.unshift({ id, name: response.name });
            if (newItemHistory.length > 10) {
              newItemHistory.pop();
            }
            localStorage.setItem(
              `_minion_${environment}_${type}_history_`,
              JSON.stringify(newItemHistory)
            );
          }
        }
      })
      .catch(err => {
        dispatch(addError('Get Item failed', err.Message));
      });
  }
};

export const saveItem = (
  modifyItemAction,
  validate,
  onBeforeSave,
  onAfterSave
) => (params, item) => dispatch => {
  if (itemTypes.ITEM_TYPES_WITH_HISTORY.includes(item.type)) {
    const { environment } = getEnvironmentConfig();
    const itemHistoryJson = localStorage.getItem(
      `_minion_${environment}_${item.type}_history_`
    );

    const itemHistory = JSON.parse(itemHistoryJson);

    const newItemHistory = itemHistory.filter(i => i.id !== item.id);
    newItemHistory.unshift({ id: item.id, name: item.name });
    if (newItemHistory.length > 10) {
      newItemHistory.pop();
    }
    localStorage.setItem(
      `_minion_${environment}_${item.type}_history_`,
      JSON.stringify(newItemHistory)
    );
  }

  if (typeof validate === 'function') {
    const validationError = validate(item);
    if (validationError) {
      dispatch(addError('Validation Error', validationError));
      return;
    }
  }

  const copy = cloneDeep(item);
  if (typeof onBeforeSave === 'function') {
    onBeforeSave(copy);
  }

  //remove our UI properties from the object before we send to api
  copy.expectedVersion = item.version;
  delete copy.id;
  delete copy.type;
  delete copy.isDirty;
  delete copy.isBusy;
  delete copy.version;
  if (copy.needsCommitMessage === true) {
    delete copy.needsCommitMessage;
    delete copy.commitMessageContext;
  }

  authorizedFetch(
    buildApiPath({
      type: item.type,
      ...params
    }),
    isItemNew(item.id) ? 'POST' : 'PUT',
    copy
  )
    .then(async response => {
      const newProperties = {
        id: response.id,
        version: response.version
      };

      if (item.type === itemTypes.RULE && response?.data) {
        newProperties.isPublishStale = response.data.isPublishStale;
      }

      // set reporting key  + postback signing key and id on save
      if (item.type === itemTypes.TRAFFIC_PARTNER && response?.data) {
        newProperties.reportingKey = response.data.reportingKey;
        newProperties.postbackSigningKeys = [
          {
            id: response.data.postbackSigningKeyId,
            key: response.data.postbackSigningKey,
            isAssigned: true
          }
        ];
      }

      // set advertiser key on save
      if (item.type === itemTypes.ADVERTISER && response?.data) {
        newProperties.adTraceApiKey = response.data?.adTraceApiKey;
      }

      dispatch(
        modifyItemAction({
          id: item.id,
          newProperties
        })
      );

      if (typeof onAfterSave === 'function') {
        const results = await onAfterSave(
          copy,
          {
            id: response.id,
            version: response.version
          },
          dispatch,
          modifyItemAction
        );
        if (Array.isArray(results)) {
          results
            .filter(r => r.isError)
            .forEach(err => {
              dispatch(addError(err.primary, err.secondary));
            });
        }
      }
      dispatch(removeToast());
      dispatch(addSuccess('Save successful'));
    })
    .catch(err => {
      dispatch(addError('Save failed', err.Message));
    });
};
